/* You can add global styles to this file, and also import other style files */
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css');
/* @import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@ng-select/ng-select/themes/material.theme.css"; */

/* @import "~@ng-select/ng-select/themes/default.theme.css";
// ... or 
@import "~@ng-select/ng-select/themes/material.theme.css"; */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* @import "~@ng-select/ng-select/themes/default.theme.css"; */

:root {
    /* size */
    --select2-single-height: 28px;
    --select2-multiple-height: 28px;

    /* label */
    --select2-label-text-color: #000;
    --select2-required-color: red;

    /* selection */
    --select2-selection-border-radius: 4px;
    --select2-selection-background: #fff;
    --select2-selection-disabled-background: #eee;
    --select2-selection-border-color: #aaa;
    --select2-selection-focus-border-color: #000;
    --select2-selection-text-color: #111;
    --select2-selection-line-height: 28px;
    --select2-selection-padding: 0 0 0 8px;

    /* selection (multiple) */
    --select2-selection-multiple-gap: 2px 5px;
    --select2-selection-multiple-padding: 2px 5px;

    /* selection: choice item (multiple) */
    --select2-selection-choice-background: #e4e4e4;
    --select2-selection-choice-text-color: #000;
    --select2-selection-choice-border-color: #aaa;
    --select2-selection-choice-close-color: #999;
    --select2-selection-choice-hover-close-color: #333;

    /* placeholder */
    --select2-placeholder-color: #999;
    --select2-placeholder-overflow: ellipsis;

    /* no result message */
    --select2-no-result-color: #888;
    --select2-no-result-font-style: italic;

    /* no result message */
    --select2-too-much-result-color: #888;
    --select2-too-much-result-style: italic;

    /* reset */
    --select2-reset-color: #999;

    /* arrow */
    --select2-arrow-color: #888;

    /* dropdown panel */
    --select2-dropdown-background: #fff;
    --select2-dropdown-border-color: #aaa;

    /* overlay */
    --select2-overlay-backdrop: transparent;

    /* search field */
    --select2-search-border-color: #aaa;
    --select2-search-background: #fff;
    --select2-search-border-radius: 0px;

    /* dropdown option */
    --select2-option-text-color: #000;
    --select2-option-disabled-text-color: #999;
    --select2-option-disabled-background: transparent;
    --select2-option-selected-text-color: #000;
    --select2-option-selected-background: #ddd;
    --select2-option-highlighted-text-color: #fff;
    --select2-option-highlighted-background: #5897fb;
    --select2-option-group-text-color: gray;
    --select2-option-group-background: transparent;
    --select2-option-padding: 6px;

    /* hint */
    --select2-hint-text-color: #888;

    /* for Material ------------------------------------------*/
    --select2-material-underline: #ddd;
    --select2-material-underline-active: #5a419e;
    --select2-material-underline-disabled: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.26) 0,
        rgba(0, 0, 0, 0.26) 33%,
        transparent 0
    );
    --select2-material-underline-invalid: red;
    --select2-material-placeholder-color: rgba(0, 0, 0, 0.38);
    --select2-material-selection-background: #ddd;
    --select2-material-option-selected-background: rgba(0, 0, 0, 0.04);
    --select2-material-option-highlighted-text-color: #000;
    --select2-material-option-selected-text-color: #ff5722;
}


.select2-container .select2-selection--multiple .select2-selection__rendered{
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    appearance: none;
    background-color: #F3F7FA;
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    padding: 15px!important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: none;
}

.select2-container--default .select2-selection--multiple{
    border:none!important
}

.tagify__tag__removeBtn{
    display: none!important;
  }

.tagify__tag__removeBtn::after{
    display: none!important;
  }

  .tagify__input{
    visibility: visible!important;
  }
  .tagInput{
    float: left!important;
    display: block!important;
    width: 100%!important;
    background:none;
    border: none;
    /* margin-left: 20px!important; */
  }

  .btnScene{
    width: 100%;
  }

  .loaderSpin{
    margin:0 auto!important
  }

  .whiteBackground{
    background-color: #fff!important;
  }

  .selectActive{
    background-color: #1E3461!important;
    color:white!important;
    transition: 120ms ease;
  }

  .selectActive .bg-main-color{
    border:1px solid #fff;
  }

  .btn-need-help{
    background-color: #1E3461!important;
  }

  .btn-need-help.disabled{
    background-color: #1E3461!important;
  }


  .popover-body {
   
    overflow: scroll;
    max-height: 300px;
    overflow-x: hidden;
  }
  

  .closePop{
    border: none;
    padding: 2px 6px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 1px;
    cursor: pointer;
  }