/* DM Sans */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,500;0,9..40,700;1,9..40,500&family=Poppins&display=swap');
/* Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'DM Sans', sans-serif;
    background: #F3F4F8;
    /* overflow: hidden; */
}

.dashboard-section,
.left-user-col {
    /* min-height: auto; */
    min-height: 800px;
}

.admin-dashboard {
    min-height: 78.1vh;
}

.pop-up {
    height: 78.1vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* text colors */

.text-main-color {
    /* color: #5051F9; */
    color:#4877CA;
    cursor: pointer;
}

.text-blue-300 {
    background-color: #1EA7FF;
    color: #fff;
}

.text-orange-300 {
    background-color: #E97342;
    color: #fff;
}

.text-light-orange-300 {
    background-color: #F59E0B;
    color: #fff;
}

.text-green-400 {
    background-color: #0ACF83;
    color: #fff;
}


.text-header-main {
    color: #232360;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
}

.text-para-main {
    color: #768396;
    word-wrap: break-word;
}

.text-history-date {
    color: #232360;
    font-family: DM Sans;
    /* font-size: 13px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bg-main-color {
    /* background-color: #5051F9; */
    background-color: #4877CA;
}

.bg-main-color:hover{
    background-color: #1E3461;
}

.text-user-content {
    color: #23235F;
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* text colors end */


/* login section */

.login-section {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.form-wrapper {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 6px 15px 50px 0px rgba(217, 217, 217, 0.20);
}

.login__title,
h2 {
    color: #232360;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.input__forms {
    border-radius: 5px;
    height: 56px;

}

.input__forms__icons {
    color: #94A2BC;
    height: 24px;
    width: 24px;
}

.input,
.select {
    background-color: #F3F7FA;
    border: 1px solid #F3F7FA;
}

.select {
    cursor: pointer;
}

.inputborder {
    border-left: 1px solid black;
}


.color-title {
    color: #232360;
}

.forgot {
    color: #232360;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    text-decoration-line: underline;
    text-transform: capitalize;
    /* padding-bottom: 22px; */
}

/* login btn   */
.btn-login,
.btn-signin,
.btn-reset {
    background-color: #5051F9;
    font-family: Poppins;
}

.color-blue {
    color: #1EA7FF;
}


/* login section end */


.body-wrapper {
    width: auto;
    flex-shrink: 0;
    /* background-image: url(/login/assets/Rectangle\ 944.png); */
    background-image: url(../images/background.png);
    background: linear-gradient(180deg, #4F8DEB -96.07%, rgba(255, 255, 255, 0.50) 100%);
    object-fit: fit;

}


/* Forgot Section */
.forgot__section {
    min-height: 100vh;
    width: auto;
    flex-shrink: 0;
    background-image: url(../images/background.png);
    object-fit: fit;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 15px;
}

.color-subtitle {
    color: #23235F;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

.no-account-text {
    color: #768396;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    text-transform: capitalize;
}

/* Forgot Section end  */

/* pop up styles  */
.btn-success {
    border-radius: 8px;
    border: none;
    background: #0ACF83;
}

.btn-warning {
    border-radius: 8px;
    border: none;
    background: #F6BC3E;
}

.btn-question {
    border-radius: 8px;
    border: none;
    background: #031B4E;
}

.btn-fail {
    border-radius: 8px;
    border: none;
    background: #FF614C;
}

.btn-confirm {
    border-radius: 8px;
    border: none;
    background: #1474BC;
}

.btn-proceed {
    border-radius: 1000px;
    background: #EDECFE;
    color: #5051F9;
    min-width: 200px!important;
}

.bth-closee:focus {
    outline: none;
    box-shadow: none;
}

.btn-closee:hover {
    /* background: #EDECFE;  */
    border: none;
    color: #5051F9;
    outline: none;
    box-shadow: none;
}

/* pop up styles end */

.rounded-20px {
    width: 68px;
    height: 68px;
    flex-shrink: 0;

    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.05);

    display: grid;
    grid-column: auto;
    align-items: center;
    justify-content: center;
}


.form__container {
    width: 552px;
    /* height: 341px; */
    flex-shrink: 0;

    border-radius: 10px;
    background: #FFF;
    box-shadow: 6px 15px 50px 0px rgba(217, 217, 217, 0.20);
}

/* register  */

.register-form-wrapper {

    border-radius: 10px;
    background: #FFF;
    box-shadow: 6px 15px 50px 0px rgba(217, 217, 217, 0.20);
}

.eye__icon {
    cursor: pointer;
    color: #94A2BC;
    border: 1px solid #F3F7FA;
}

.fw-medium-500 {
    font-weight: 500;
}

.btn-width-100 {
    width: 50%;
}

/* register end */

/* admin-dashboard  */
.admin__body-wrapper {
    height: auto;
    background-image: url(../images/background.png);
    background: linear-gradient(180deg, #4F8DEB -96.07%, rgba(255, 255, 255, 0.50) 100%);
    object-fit: fit;

}

.admin__dashboard__section {
    overflow: hidden;
    width: 100%;
    height: 90%;
    bottom: 0;
    overflow-y: scroll;
    /* position: absolute; */
    position: relative;
    padding-top:30px;

}

.profile__icon {
    height: 40px;
    width: 40px;

    background-color: #E7E7FF;

}

.color-main {
    color: #5051F9;
}

/* logout color */
.admin-title-logout {
    color: #FF614C;
}

/* logout color end */

/* admin pop up */
.admin-profile-pop-up-child {
    position: absolute;
    top: 166px;
    left: 40px;
    right: 0px;
    width: 0px;
    height: 0px;
    object-fit: cover;
    cursor: pointer;
}

.john-smith {
    position: absolute;
    top: 136px;
    left: 84px;
    font-size: 20px;
    color: #232360;
    font-family: DM Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.johnsmithcom {
    position: absolute;
    top: 165px;
    left: 82px;
    font-size: 14px;
    font-weight: 500;
    color: #768396;
    text-decoration: none;

}

.edit-profile {
    margin: 0;
    position: relative;
    color: #FFF;
    text-align: center;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.edit-profile-wrapper {
    position: absolute;
    top: 203px;
    left: 42px;
    border-radius: 1000px;
    background-color: #5051f9;
    box-shadow: 0px 8px 20px rgba(80, 81, 249, 0.24);
    display: flex;
    flex-direction: row;
    padding: 11px 60px;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    color: #fff;
}

.group-child {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #e7e7ff;
    width: 83px;
    height: 83px;
}

.icon {
    position: absolute;
    top: 10.38px;
    left: 10.38px;
    width: 62.25px;
    height: 62.25px;
    object-fit: cover;
}

.ellipse-parent {
    position: absolute;
    top: 33px;
    left: 97px;
    width: 83px;
    height: 83px;
}

.change-password {
    position: absolute;
    top: 275px;
    left: 42px;
    color: #232360;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contact-support {
    position: absolute;
    top: 318px;
    left: 42px;
    color: #232360;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* display: none; */
}

.text-contact-support {
    color: #232360;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto 0px;
}

.admin-profile-pop-up-item {
    position: absolute;
    top: 328px;
    left: 42px;
    background-color: #e8edf1;
    width: 194px;
    height: 1px;
}

.user-profile-pop-up-item {
    position: absolute;
    top: 349px;
    left: 42px;
    background-color: #e8edf1;
    width: 194px;
    height: 1px;
}

.log-out {
    position: absolute;
    top: 11px;
    left: 59px;
    display: inline-block;
    width: 135px;
    color: #232360;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.group-item {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: #f3f7fd;
    width: 44px;
    height: 44px;
}

.log-in-icon {
    position: absolute;
    top: 13.6px;
    left: 13.6px;
    width: 16.8px;
    height: 16.8px;
    object-fit: cover;
}

.log-out-parent {
    position: absolute;
    top: 351px;
    left: 42px;
    width: 194px;
    height: 44px;
    cursor: pointer;
}

.log-out-parent-user {
    position: absolute;
    top: 357px;
    left: 42px;
    width: 194px;
    height: 44px;
    cursor: pointer;
}

.user-profile-pop-up {
    display: none;
    position: fixed;
    right: 45px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid #e3ebee;
    box-sizing: border-box;
    width: 277px;
    height: 434px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #232360;
    font-family: 'DM Sans';
    /* opacity: 0; */
}


.admin-profile-pop-up {
    display: none;
    position: fixed;
    right: 45px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid #e3ebee;
    box-sizing: border-box;
    width: 277px;
    height: 416px;
    overflow: hidden;
    text-align: left;
    font-size: 16px;
    color: #232360;
    font-family: 'DM Sans';
    /* opacity: 0; */
    z-index: 999999;
}

.admin-profile-pop-up.show {
    display: block;
}

.user-profile-pop-up.show {
    display: block;
}

.text-scenario-title {
    color: #232360;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* admin pop up end */

/* scenario pop up  */
.modal-content.modal-content-help {
    height: 933px;
}

.accordion-border {
    border-radius: 9px;
    border: 4px solid #E5E5ED;
    /* height: auto; */
}

.scenario-span-feedback {
    position: relative;
    border-radius: 4px;
    background: #F3F4F8;
}

/* accordion item */
/* Custom CSS for the accordion hover effect */
.accordion-item:hover .accordion-collapse {
    display: block;
}

/* Optional animation for a smoother transition */
.accordion-item .accordion-collapse {
    display: none;
    transition: max-height 0.2s ease-in-out;
    max-height: 0;
    overflow: hidden;
}

.accordion-item:hover .accordion-collapse {
    max-height: 2000px;
    /* Adjust this value according to your content */
}

.accordion-items {
    display: none;
}

.accordion-items:hover {
    display: block;
}

/* accordion item end */

/* scenario pop up end */


.bg-color__icon {
    background-color: #F3F7FD;
}

.table-th-grey {
    /* color: #768396; */
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #768396;

}


.admin-title-left {
    color: #8C97A8;
    font-size: 20px;
    font-family: DM Sans;
    font-weight: 500;
    word-wrap: break-word;
}

.admin-title-right {

    color: #1E1E1E;
    font-size: 32px;
    font-family: DM Sans;
    font-weight: 700;
    word-wrap: break-word;
}

.admin-title-time {
    color: #1E1E1E;
    font-size: 15px;
    font-family: DM Sans;
    font-weight: 700;
    word-wrap: break-word;
}

/* Manage most user */

#most-container,
.most-recent-container,
#feedbacks-container,
.most-feedbacks-container,
#data-container,
.most-data-container,
#manage-container,
.most-manage-container {
    overflow-x: auto;
}

.most-recent-container table,
.most-feedbacks-container table,
.most-data-container table,
.most-manage-container table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    white-space: nowrap;
}

.most-recent-container table thead th td,
.most-manage-container table thead th td {
    padding: 6px 0;
    padding-left: 20px;
}


.tablediv {
    min-height: 32px;
    margin-bottom: 8px;

}

.tablediv span{
    width: 40px;
    /* border: 1px solid red; */
    height: 20px;
}

.tablediv p{
    width: 40px;
}


/* User column */
#most-container table th:nth-child(1) {
    width: 210px;
}

#most-container table th:nth-child(2) {
    width: 215px;
}

/* Semester column */
#most-container table th:nth-child(3) {
    width: 220px;
}

/* total question asked column */
#most-container table th:nth-child(4) {
    width: 310px;
}

/* total question asked column */
#most-container table th:nth-child(6) {
    width: 100px;
}

/* Feedback (5) and Courses (2) */

#most-container table th:nth-child(5) {
    margin: auto;
    width: 200px;
}


.admin-most-left {
    color: #232360;
    font-size: 30px;
    font-family: DM Sans;
    font-weight: 500;
    word-wrap: break-word;
}

.admin-most-right {
    color: #1EA7FF;
    font-size: 17px;
    font-family: DM Sans;
    font-weight: 500;
    word-wrap: break-word;
}


.text-most-name {
    color: #768396;
    font-size: 17px;
    font-family: DM Sans;
    font-weight: 500;
    word-wrap: break-word;
}

.vertical-dots-gray {
    color: #768396;
    cursor: pointer;
}

#manage-container table th:nth-child(1) {
    width: 245px;
}

#manage-container table th:nth-child(2) {
    width: 210px;
}

#manage-container table th:nth-child(3) {
    width: 227px;
}

#manage-container table th:nth-child(4) {
    width: 247px;
}

#manage-container table th:nth-child(5) {
    width: 207px;
}

/* Manage most user end */

/* most recent feedbacks */
#feedbacks-container table th:nth-child(1) {
    width: 215px;
}

#feedbacks-container table th:nth-child(2) {
    width: 405px;
}

#feedbacks-container table th:nth-child(3) {
    width: 380px;
}

#feedbacks-container table th:nth-child(4) {
    width: 170px;
}



.icon-color-blue {
    color: #23B2FF;
}

/* most recent feedbacks end */

/* Data Source column */
#data-container table th:nth-child(1) {
    width: 155px;
}

#data-container table th:nth-child(2) {
    width: 155px;
}

#data-container table th:nth-child(3) {
    /* width: 485px; */
}

#data-container table th:nth-child(4) {
    width: 210px;
}

#data-container table th:nth-child(5) {
    width: 170px;
}

.text-add-subtitle {
    color: #768396;
    text-align: center;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


/* radio style */
/* Custom radio button styles */
.custom-radio {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    /* Add some spacing between radio buttons */
    cursor: pointer;
}

.custom-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-radio .form-check-label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    /* Size of the custom radio button */
    height: 20px;
    /* Size of the custom radio button */
    border-radius: 50%;
    /* Makes it a circle */
    background-color: #fff;
    /* Background color of the custom radio button */
}

.custom-radio input[type="radio"]:checked+.form-check-label::before {
    background-color: #5051f9;
    /* Color when checked */
    border: 1px solid #5051f9;
    /* Border color when checked */
}

.custom-radio i {
    margin-right: 5px;
    /* Add spacing between the icon and label text */
}

/* Add some hover effect if desired */
.custom-radio:hover .form-check-label::before {
    background-color: #f1f1f1;
    border-color: #ccc;
}

/* radio style end */
.data-sources-form {
    display: none;
}

.textarea-form {
    height: 88px;

}

.textarea-form-body {
    height: 617px;

}

.textarea-form-modal {
    height: 580px;
}



.text-category {
    margin-top: 6px;
    color: #768396;
    font-family: DM Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.text-category-click {
    text-decoration: none;
    color: #4877CA;
    font-family: DM Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.input-upload-div {
    border: 1px solid #E5E5ED;
    height: 158px;
}

/* Data Source column end */

/* Admain - Manage Students */
/* 
.input-group-container {
    border: 1px solid white;
    border-radius: 25px;
    width: 252px;
    height: 44px;
    padding: 13px 8px;
} */

.input-search {
    border: 1px solid white;
    /* border-radius: 25px; */
    /* width: 252px; */
    height: 44px;
    padding: 13px 8px;
}

.input-search {
    border: none;
}

.input-search:focus {
    outline: none;
    box-shadow: none;
}

.text-manage {
    position: relative;
    font-size: 30px;
    font-weight: 500;
    font-family: 'DM Sans';
    color: #232360;
    text-align: left;
}

.block {
    color: #768396;
    display: block;
    float: right;

}

.block:after {
    content: 'Malesuada odio arcu sit nunc mi et gravida non. Mattis in nulla accumsan et.';
    display: inline-block;
}


.btn-manage-back {
    display: inline-flex;
    padding: 12px 25px;
    /* margin: auto; */
    /* flex-direction: column; */
    color: #5051F9;
    font-weight: 700;
    justify-content: center;
    /* align-items: flex-end; */
    gap: 10px;

    border-radius: 23px;
    border: 1px solid var(--blue, #5051F9);
}

.btn-manage-add {
    display: inline-flex;
    padding: 12px 25px;
    /* margin: auto; */
    color: #fff;
    font-weight: 700;
    justify-content: center;
    gap: 10px;
    text-transform: capitalize;

    border-radius: 23px;
    background: var(--blue, #5051F9);
}

.btn-need-help {
    display: inline-flex;
    padding: 12px 25px;
    /* height: 46px; */
    /* margin: auto; */
    color: #fff;
    font-weight: 700;
    justify-content: center;
    gap: 10px;
    text-transform: capitalize;

    border-radius: 23px;
    background-color: #1E3461;
}

.btn-need-help:hover {
    border-radius: 23px;
    background-color: #1E3461;
}

.circle-div {
    position: relative;
    border-radius: 16.5px;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    width: 33px;
    height: 33px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-div.active {
    position: relative;
    border-radius: 16.5px;
    border: 1px solid #5051f9;
    box-sizing: border-box;
    width: 33px;
    height: 33px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}

/* Admain - Manage Students end */


/* Pop up action style */
.admin-profile-pop-up-child {
    position: absolute;
    top: 176px;
    left: 40px;
    width: 0px;
    height: 0px;
    object-fit: cover;
}

.search-icon {
    position: relative;
    width: 16.5px;
    height: 16.5px;
    object-fit: cover;
}

.view {
    position: relative;
    font-weight: 500;
}

.search-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin:5px;
}

.frame-wrapper {
    border-radius: 6px;
    width: 153px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 10px 17px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
}

.admin-profile-pop-ups-inner {
    position: absolute;
    top: 10px;
    left: calc(50% - 76.5px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.search-icon1 {
    position: relative;
    width: 17px;
    height: 17px;
    object-fit: cover;
}

.frame-div {
    position: absolute;
    top: 52px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.search-icon2 {
    position: relative;
    width: 16px;
    height: 16px;
    object-fit: cover;
}

.admin-profile-pop-up-inner1 {
    position: absolute;
    top: 94px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.admin-profile-pop-ups {
    display: none;
    position: absolute;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid #e3ebee;
    box-sizing: border-box;
    /* width: 100%; */
    height: 146px;
    overflow: hidden;
    text-align: left;
    font-size: 17px;
    color: #8a91ac;
    font-family: 'DM Sans';
}

/* Pop up action style end */

.cursor-pointer {
    cursor: pointer;
}

.nav-item {
    color: #232360;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;

}

.nav-icon {
    height: 30px;
    width: 30px;
}


.navbar-nav .nav-item.active a {
    position: relative;
    color: #5051F9;
}

.navbar-nav .nav-item.active a::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    bottom: 0;
    background-color: #5051f9;
    height: 4px;
    border-radius: 100px;
}

.admin__container {
    margin-top: 80px;
    /* padding-top: 20px; */
}


/* Edit Profile Style  */

.profile-bar {
    cursor: pointer;
    margin-bottom: 5px;
}

.profile-bar-icon {
    color: #232360;
    fill: #232360;
}

.profile-bar-active {
    border: 1px solid #5051f9;
    border-radius: 6px;
}


.profile-icon-wrapper {
    width: 124px;
    border-radius: 100%;
    height: 124px;
    background-color: #E7E7FF;
}

.edit-profile-title {
    color: #232360;
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.profile-icon {
    width: 114px;
    height: 114px;
    display: grid;
    place-items: center;
    margin: auto;
}

.input-file {
    background-color: #FFF;
    cursor: pointer;
    color: #031B4E;
    text-align: center;
    font-family: DM Sans;
    /* font-size: 14px; */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

input[type="file"] {
    display: none;
}


.profile-subtitle {
    color: #3B3F58;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
}

/* Edit Profile Style end  */


/* admin-dashboard end */

/* User Dashboard  */
.textarea-help-form {
    height: 189px;
}

.btn-help-submit {
    height: 40px;
}

.user-category {
    /* display: none; */
}

/* #category {
    display: none;
} */

#scenario,
#coversation,
#search {
    /* display: none; */
}

/* #search {
    display: block;
} */
.text-last-login {
    color: #768396;
    text-align: right;
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text-last-login-time {
    color: #232360;
    text-align: right;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text-title-conversation {
    color: #232360;
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.input-forms-conversation {
    height: 80px;
    padding: 3px 14px 25px 17px;
}

.text-content-suggestion {
    color: #232360;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    padding: 15px 18px;
    border-radius: 10px;
}

/* User Dashboard end */

/* footer style */
.bg-blue-footer {
    color: #FFF;
    text-align: center;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 40px;
    background-color: #1E3461;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

#year {
    color: #fff;
}

/* footer style end */

@media only screen and (max-width: 600px) {
    .btn-width-100 {
        width: 100%;
    }
}

.fL{
    float: left;
}

/* CUSTOM CSS */
.modal-footer{
    display: inherit!important;
}

.john-smith1{
    text-align: center!important;
    font-size: 25px!important;
    margin-top: 130px!important;
}

.johnsmithcom1{
    text-align: center!important;
    font-size: 10px!important;
}

table {
    table-layout: fixed;
    /* width: 400px; */
    border-collapse: collapse;
  }

  table td {
    word-wrap: break-word;
    /* border: 1px solid black; */
  }

  .text-most-name{
    text-wrap: pretty!important;
    word-break: break-all!important
  }

  /* TOAST CSS */

  .colored-toast.swal2-icon-success {
    background-color: #a5dc86 !important;
    width: 100%;
  }
  
  .colored-toast.swal2-icon-error {
    background-color: #f27474 !important;
  }
  
  .colored-toast.swal2-icon-warning {
    background-color: #f8bb86 !important;
  }
  
  .colored-toast.swal2-icon-info {
    background-color: #3fc3ee !important;
  }
  
  .colored-toast.swal2-icon-question {
    background-color: #87adbd !important;
  }
  
  .colored-toast .swal2-title {
    color: white;
  }
  
  .colored-toast .swal2-close {
    color: white;
  }
  
  .colored-toast .swal2-html-container {
    color: white;
  }

  .swal2-popup.swal2-toast {
    padding: .5em 1em !important;
    display: flex !important;
    width: auto !important;
}
.swal2-popup.swal2-toast .swal2-title {
    margin: .75em .25em !important;
}

.table-borderless > :not(caption) > * > * {
    border-bottom-width: 0;
    border: 1px solid #ccc;
    padding: 5px;
  }

table  .text-most-name {
    font-size: 14px;
    word-break: break-word!important;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

#manage-container table th{
    word-break: break-word!important;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.table-th-grey{
    font-weight: bolder;
    text-wrap: pretty!important;
    word-break: break-word;
}

/* MAT PAGINATOR */
.mat-paginator {
    display: flex;
    justify-content: center;
}

.pos-absolute {
    position: absolute;
}

table{
    border: 1px solid #ccc!important;
}

.closeModal{
    position: absolute;
    right: 50px;
    top:40px;
}

.deleteBorder{
    border:none!important;
}

.input-group  tagify.form-control{
    width: 100%!important;
}

#basic-url{
    width: 100%!important;
}

.alertMessage{
    height: auto;
    position: relative;
    width: 100%!important;
    display: inline-grid;
}

.mat-horizontal-stepper-header{
    display: none!important;
}

.mat-horizontal-content-container{
    margin-top: 20px;
}

.mat-stepper-horizontal-line{
    display: none;
}

.mat-mdc-radio-group{
    margin-bottom: 20px;
    margin-top: 20px;
}

.container-fluid{
    /* min-height: 800px; */
}

.left-user-col{
    /* min-height: 600px;   */
}

.mat-mdc-icon-button.mat-mdc-button-base{
    --mdc-icon-button-state-layer-size: 30px;
    padding:0
}

.mat-horizontal-stepper-content{
    position: relative;
}

table th{
    
}

.cdk-overlay-container{
    z-index: 1500!important;
}